import { AxiosResponse } from 'axios';
import {ENTITY_CLIENT} from "@/api/misc/EntityClient";

/**
 * repository that deals with all the api requests that have to do with news
 */
export default class TwoFactorAuthenticationRepository {
    private static baseUrl: string = 'api/2fa';

    /**
     * gets data for the qr code
     */
    public static getQRCode(): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get(`${this.baseUrl}`);
    }

    /**
     * actviates two factor authentication
     * @param code
     */
    public static activateTwoFactor(code: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.post(`${this.baseUrl}?code=${code}`);
    }

    /**
     * deletes the two factor authentication from the user
     * @param code
     */
    public static deleteTwoFactor(code: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.delete(`${this.baseUrl}?code=${code}`);
    }
}
