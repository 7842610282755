
import {Component} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import {AUTH_STORE_NAME, AuthStoreMutations} from "@/store/auth.store";
import {namespace} from "vuex-class";
import TwoFactorAuthenticationRepository from "@/api/repositories/TwoFactorAuthenticationRepository";
import Account from "@/models/Account";

const AuthStore = namespace(AUTH_STORE_NAME);

@Component({
  components: {
    TwoFactorModalComponent: () => import(
      /* webpackChunkName: "TwoFactorModalComponent" */
      '@/components/Authentication/TwoFactorModal.component.vue'
    ),
  },
})
export default class ChangeSecurityComponent extends mixins(ErrorMessageHandlerMixin) {
  @AuthStore.Mutation(AuthStoreMutations.SET_ACCOUNT)
  private updateAccountAction!: (account: Account) => void;

  /**
   * v-model to track at which state the user
   */
  private isUsingTwoFactor: boolean = this.hasActivatedTwoFactor;

  /**
   * flag that indicates if the codeDialog ist shown
   */
  private showDialog: boolean = false;

  /**
   * is called when the switch is toggled, opens the edit dialog
   */
  private onSwitchToggled(value: boolean) {
    this.showDialog = true;
  }

  /**
   * checks if the two factor authentication is enabled
   * @private
   */
  private get hasActivatedTwoFactor(): boolean {
    return this.$getCurrentAccount()?.twoFactorEnabled ?? false;
  }

  /**
   * is called when the qr code was entered, tries to activate / deactivate the two factor authentication
   * @param value
   * @private
   */
  private async onCodeEntered(value: string) {
    try {
      if(this.hasActivatedTwoFactor) {
        const { data } = await TwoFactorAuthenticationRepository.deleteTwoFactor(value);
        this.updateTwoFactorInAccount(data['activated']);
        this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.DEACTIVATED_SUCCESS');
      } else {
        const { data } = await TwoFactorAuthenticationRepository.activateTwoFactor(value);
        this.updateTwoFactorInAccount(data['activated']);
        this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.ACTIVATED_SUCCESS');
      }

      this.showDialog = false;
      this.closeDialog(true);
    } catch(e) {
      this.$handleError(e, () => {
        switch (e.status) {
          case 422:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.WRONG_TWO_FACTOR_CODE');
            break;
          default:
            if(this.hasActivatedTwoFactor) this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.DEACTIVATING_TWO_FACTOR_FAILED');
            else this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.ACTIVATING_TWO_FACTOR_FAILED');
        }
      });
    }
  }

  /**
   * updates the account to have the updated two factor flag
   * @param value
   * @private
   */
  private updateTwoFactorInAccount(value: boolean) {
    const old = this.$getCurrentAccount();
    const updated = Account.parseFromObject({...old, twoFactorEnabled: value});
    this.updateAccountAction(updated);
  }

  /**
   * is called when the modal should be closed, closes the modal
   * @private
   */
  private closeDialog(success: boolean) {
    this.showDialog = false;
    if(!success) this.isUsingTwoFactor = !this.isUsingTwoFactor;
  }
}
